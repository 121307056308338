<template>
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <router-link :to="{ name: 'depot-page' }" class="main-tab__title"
        >Склад</router-link
      >
      <h2 v-if="state.stockData" class="main-tab__title active">
        {{ state.stockData?.title }}
      </h2>
    </div>

    <button
      class="btn btn-gold btn-md"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#add-reserve-modal"
      :disabled="!isReserveActive"
    >
      Добавить в резерв
    </button>
  </div>

  <div class="d-flex">
    <div class="depot__filters-wrapper">
      <SearchFilter v-model="state.searchFilter" />

      <div class="depot__filters">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h4 class="depot__filters-title">Фильтр</h4>
          <button
            type="button"
            class="depot__filters-reset"
            @mousedown="resetFilters"
          >
            Очистить все
          </button>
        </div>

        <span class="line-separator"></span>

        <h5 class="depot__filters-subtitle">Тип</h5>
        <v-select
          class="depot__select"
          placeholder="Все типы"
          label="title"
          :options="[
            { id: 'product', title: 'Продукт' },
            { id: 'material', title: 'Сырье' },
          ]"
          :reduce="(item) => item.id"
          v-model="state.type"
        ></v-select>

        <template v-if="state.type == 'product'"
          ><h5 class="depot__filters-subtitle">Тип продукта</h5>
          <v-select
            class="depot__select"
            placeholder="Все типы"
            label="type"
            :options="state.product_type_options"
            :reduce="(item) => item.type"
            v-model="state.product_type"
          ></v-select
        ></template>
      </div>
    </div>

    <FixedColumnTable>
      <table class="table main-table bordered text-center">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th class="text-start" scope="col">Наименование</th>
            <th scope="col">Тип</th>
            <th scope="col">Тип продукта</th>
            <th scope="col"><span class="text-nowrap">Общее кол-во </span></th>
            <th scope="col">Задействующее кол-во</th>
            <th scope="col">Остаток</th>
            <th scope="col">Резерв</th>
            <th scope="col">Количество Брака</th>
            <th scope="col">Количество Уценки</th>
            <th scope="col">Остаточное количество</th>
            <th scope="col">Дата обновления</th>
            <th scope="col">Действия</th>
            <th scope="col">Резерв</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in state.materials" :key="item.id">
            <td :style="{ width: item.id.length }">{{ item.id }}</td>
            <td class="text-start">
              {{ item.title }}
            </td>
            <td>
              {{ item.type ?? "-" }}
            </td>
            <td>
              {{ item.product_type ?? "-" }}
            </td>
            <td>
              {{ convertNumber(item.total_quantity) ?? "-" }}
            </td>
            <td>
              {{ convertNumber(item.in_work_quantity) ?? "-" }}
            </td>
            <td>{{ convertNumber(item.real_quantity) ?? "-" }}</td>
            <td>{{ convertNumber(item.reserve_quantity) ?? "-" }}</td>
            <td>{{ convertNumber(item.defect_quantity) ?? "-" }}</td>
            <td>{{ convertNumber(item.discount_quantity) ?? "-" }}</td>
            <td>{{ convertNumber(item.remaining_quantity) ?? "-" }}</td>
            <td>{{ item.date ? formatDateTime(item.date) : "-" }}</td>
            <td>
              <div class="d-flex">
                <button
                  type="button"
                  class="btn btn-outline-gold btn-icon me-5"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-material-modal"
                  @click="entityToUpdate = item"
                >
                  <inline-svg
                    :src="require('@/assets/images/edit-1.svg')"
                    fill="#DAA14C"
                  />
                </button>

                <button
                  @click="removeStockMaterial(item.id)"
                  type="button"
                  class="btn btn-outline-gold btn-icon"
                >
                  <inline-svg
                    :src="require('@/assets/images/bin.svg')"
                    fill="#DAA14C"
                  />
                </button>
              </div>
            </td>

            <td>
              <router-link
                :to="{
                  name: 'reserve-products-page',
                  params: {
                    reserveId: parseInt(item.id),
                    materialId: $route.params.id,
                  },
                }"
                class="btn btn-gold w-100"
                :class="{ disabled: !isReserveActive }"
              >
                Перейти
              </router-link>
            </td>
          </tr>

          <tr v-show="!state.materials.length && !isDataLoading">
            <td colspan="7">
              <span class="d-block text-bold my-3">Ничего не найдено!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </FixedColumnTable>
  </div>
  <Pagination
    v-model:currentPage="state.currentPage"
    :totalPages="state.totalPages"
  />

  <MaterialModal
    id="edit-material-modal"
    title="Редактировать материал"
    submitBtnText="Сохранить"
    :entityToUpdate="entityToUpdate"
    :stockFilterOptions="state.stockFilterOptions"
    :measurementFilterOptions="state.measurementFilterOptions"
    :apiRequest="updateStockMaterial"
    :refetch="getData"
  />
  <ReserveModal
    id="add-reserve-modal"
    title="Добавить в резерв"
    submitBtnText="Добавить"
    :productOptionList="state.productOptions"
    :apiRequest="addReserveProducts"
    :refetch="getData"
  />
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { convertNumber } from "@/helpers/stringUtils";
import { formatDateTime } from "@/helpers/dateTimeUtils";
import { errorAlert, confirmAlert } from "@/helpers/alerts";
import useLoadingModal from "@/mixins/useLoadingModal";
import {
  getStockTitle,
  getStockProductTypes,
  getAllStock,
  getStockMaterials,
  updateStockMaterial,
  deleteStockMaterial,
} from "@/api/stock";
import { getMeasurement } from "@/api/measurement";
import { addReserveProducts } from "@/api/reserveProduct";
import { getProductsByStockId } from "@/api/product";

import FixedColumnTable from "@/components/ui/FixedColumnTable.vue";
import MaterialModal from "@/components/depot/MaterialModal.vue";
import ReserveModal from "@/components/reserveProducts/ReserveModal.vue";
import SearchFilter from "@/components/depot/SearchFilter.vue";
import Pagination from "@/components/ui/Pagination.vue";

export default {
  components: {
    FixedColumnTable,
    MaterialModal,
    ReserveModal,
    SearchFilter,
    Pagination,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isReserveActive = computed(
      () => userPermissions.value["22"]?.permissions["34"] ?? false
    );

    const state = reactive({
      currentPage: 1,
      totalPages: 1,
      type: null,
      product_type: null,
      product_type_options: [],
      stockData: null,
      searchFilter: null,
      stockFilterId: route.params.id,
      stockFilterOptions: [],
      measurementFilterOptions: [],
      productOptions: [],
      materials: [],
    });

    const entityToUpdate = ref(null);
    const { isDataLoading } = useLoadingModal();

    onMounted(async () => {
      getData();

      try {
        state.stockFilterOptions = await getAllStock();
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    });

    onMounted(async () => {
      state.measurementFilterOptions = await getMeasurement();
    });

    onMounted(async () => {
      state.stockData = await getStockTitle(state.stockFilterId);
    });

    onMounted(async () => {
      state.productOptions = await getProductsByStockId(state.stockFilterId);
    });

    onMounted(async () => {
      state.product_type_options = await getStockProductTypes();
    });

    watch(
      () => state.currentPage,
      () => {
        getData();
      }
    );

    watch(
      () => [state.searchFilter, state.stockFilterId, state.product_type],
      () => {
        if (state.currentPage != 1) state.currentPage = 1;
        else getData();
      }
    );

    watch(
      () => state.type,
      () => {
        if (state.product_type != null) state.product_type = null;
        else if (state.currentPage != 1) state.currentPage = 1;
        else getData();
      }
    );

    const resetFilters = () => {
      state.searchFilter = null;
      state.type = null;
      state.product_type = null;
    };

    async function getData() {
      try {
        isDataLoading.value = true;
        const res = await getStockMaterials(
          state.currentPage,
          state.stockFilterId,
          state.searchFilter,
          state.type,
          state.product_type
        );
        state.materials = res.data;
        state.totalPages = res.meta?.last_page;
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        isDataLoading.value = false;
      }
    }

    function removeStockMaterial(id) {
      confirmAlert("Подтвердить действие").then(async (result) => {
        if (result.isConfirmed) {
          try {
            isDataLoading.value = true;
            await deleteStockMaterial(id);
            getData();
          } catch {
            isDataLoading.value = false;
            errorAlert("Ошибка загрузки, попробуйте еще раз!");
          }
        }
      });
    }

    return {
      isReserveActive,

      state,
      isDataLoading,
      entityToUpdate,
      formatDateTime,
      convertNumber,
      resetFilters,
      getData,
      updateStockMaterial,
      removeStockMaterial,
      addReserveProducts,
    };
  },
};
</script>
