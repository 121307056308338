import axios from '@/api/axios';
import moment from 'moment';

const getReserveProducts = async (stock_id, product_id, page, filter) => {
  const { data: response } = await axios.get('/reserve-products', {
    params: {
      stock_id,
      product_id,
      page,
      'filter[search]': filter,
    },
  });
  return response;
};

const getReserveProductMaterials = async (productId) => {
  const { data: response } = await axios.get(`reserve-products/${productId}/materials`);
  return response.data;
};

const addReserveProducts = async (data) => {
  const { data: response } = await axios.post('/reserve-products', {
    ...data,
    reserve_date: moment(new Date(data.reserve_date)).format('YYYY-MM-DD'),
    phone_number: data.phone_number?.replace(/\D/g, '').substring(1),
  });
  return response;
};

const updateReserveProductStatus = async (reserveProductId, stock_id, is_taken) => {
  const { data: response } = await axios.put(`/reserve-products/${reserveProductId}`, {
    stock_id,
    is_taken,
  });
  return response;
};

export {
  getReserveProducts,
  addReserveProducts,
  updateReserveProductStatus,
  getReserveProductMaterials,
};
