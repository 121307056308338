<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 834px">
      <form @submit.prevent="onSubmitForm" class="modal-content">
        <div class="modal-header">
          <h5 class="main-form-control__title" id="staticBackdropLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :disabled="state.isFormLoading"
          ></button>
        </div>

        <div class="modal-body">
          <div class="add-staff px-5 py-3">
            <input class="visually-hidden" type="text" />

            <div>
              <label class="main-form-control__label"> Наименование: </label>

              <div class="main-form-control__input-wrapper">
                <input
                  class="main-form-control__input"
                  :class="{ error: v$.title.$error }"
                  type="text"
                  placeholder="Введите наименование"
                  v-model="state.title"
                  :disabled="entityToUpdate != null"
                />
                <div
                  v-if="entityToUpdate != null"
                  class="main-form-control__input-svg-wrapper"
                >
                  <inline-svg
                    :src="require('@/assets/images/lock.svg')"
                    fill="#717C99"
                  />
                </div>
              </div>

              <span class="error-warning" v-if="v$.title.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div>
              <label class="main-form-control__label"> Склад: </label>

              <v-select
                class="main-form-control__select stock-select"
                :class="{ error: v$.stock.$error }"
                placeholder="Выберите склад"
                label="title"
                :options="stockFilterOptions"
                :clearable="false"
                :reduce="(item) => item"
                v-model="state.stock"
              ></v-select>

              <span class="error-warning" v-if="v$.stock.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div>
              <label class="main-form-control__label"> Количество: </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.total_quantity.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.total_quantity"
              />

              <span class="error-warning" v-if="v$.total_quantity.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div>
              <label class="main-form-control__label">
                Единица измерения:
              </label>

              <v-select
                class="main-form-control__select"
                :class="{ error: v$.measurement.$error }"
                placeholder="Единица измерения"
                label="title"
                :options="measurementFilterOptions"
                :clearable="false"
                v-model="state.measurement"
              ></v-select>

              <span class="error-warning" v-if="v$.measurement.$error"
                >* Данное поле обязательно</span
              >
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="px-5">
            <button
              type="button"
              class="btn btn-white btn-md"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md"
              :disabled="state.isFormLoading"
            >
              <span>{{ submitBtnText }}</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

import useModal from "@/mixins/useModal";
import { required, numeric } from "@vuelidate/validators";

export default {
  props: [
    "entityToUpdate",
    "title",
    "submitBtnText",
    "apiRequest",
    "refetch",
    "stockFilterOptions",
    "measurementFilterOptions",
  ],
  setup(props) {
    const state = reactive({
      isFormLoading: false,
      title: null,
      stock: null,
      total_quantity: null,
      measurement: null,
    });
    const rules = {
      title: { required },
      stock: { required },
      total_quantity: { required, numeric, minValue: (item) => item >= 1 },
      measurement: { required },
    };
    const { modalRef, v$, onSubmitForm, onResetForm } = useModal(props, {
      state,
      rules,
    });

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
    };
  },
};
</script>

<style>
.stock-select .vs__selected {
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 37.5px;
}
</style>