import moment from 'moment';

function calculateTimeDifference(startTime, endTime) {
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  let hourDifference = endHour - startHour;
  let minuteDifference = endMinute - startMinute;

  if (minuteDifference < 0) {
    hourDifference--;
    minuteDifference += 60;
  }

  if (hourDifference && minuteDifference)
    return hourDifference + ' ч ' + minuteDifference + ' минут';
  else if (!minuteDifference) return hourDifference + ' ч';
  else return minuteDifference + ' минут';
}

function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const day = String(dateTime.getDate()).padStart(2, '0');
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const year = dateTime.getFullYear();
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

function formatDate(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const day = String(dateTime.getDate()).padStart(2, '0');
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const year = dateTime.getFullYear();

  return `${day}.${month}.${year}`;
}

function getCorrectDayForm(days) {
  if (days % 10 === 1 && days % 100 !== 11) {
    return 'день';
  } else if (days % 10 >= 2 && days % 10 <= 4 && (days % 100 < 10 || days % 100 >= 20)) {
    return 'дня';
  } else {
    return 'дней';
  }
}

function getDifference(startDate, endDate) {
  if (!startDate || !endDate) return '-';

  const start = moment(startDate);
  const end = moment(endDate);

  const duration = moment.duration(end.diff(start));

  if (duration.asDays() >= 1) {
    const days = Math.floor(duration.asDays());
    return `${days} ${getCorrectDayForm(days)}`;
  } else {
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }
}

export { calculateTimeDifference, formatDateTime, formatDate, getDifference };
