<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 834px">
      <form @submit.prevent="onSubmitForm" class="modal-content">
        <div class="modal-header flex-column">
          <div class="d-flex align-item-center justify-conyent-between w-100">
            <h5 class="main-form-control__title" id="staticBackdropLabel">
              {{ title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :disabled="state.isFormLoading"
            ></button>
          </div>

          <div class="main-small-alert w-100 mt-2">
            <inline-svg
              class="main-small-alert__icon"
              :src="require('@/assets/images/alert.svg')"
              fill="#717C99"
            />

            <p class="main-small-alert__text">
              Пункты помеченные символом <span class="text-danger">*</span>,
              обязательны для проверки/заполнения.
            </p>
          </div>
        </div>

        <div class="modal-body">
          <div class="create-task px-5 py-3">
            <div class="create-task__inner">
              <input class="visually-hidden" type="text" />

              <div class="d-block column-span-2">
                <label class="main-form-control__label"> ФИО: </label>

                <input
                  class="main-form-control__input"
                  :class="{ error: v$.full_name.$error }"
                  type="text"
                  placeholder="Введите ФИО"
                  v-model="state.full_name"
                />

                <span class="error-warning" v-if="v$.full_name.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label"> Продукт: </label>

                <v-select
                  label="title"
                  placeholder="Выберите продукт"
                  class="main-form-control__select task-select"
                  :class="{ error: v$.product_id.$error }"
                  :options="productOptionList"
                  :clearable="false"
                  :reduce="(item) => item.id"
                  v-model="state.product_id"
                  ><template v-slot:no-options>Ничего не найдено.</template>
                  <template #selected-option="selected_option">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :title="selected_option.title"
                      >{{ selected_option.title }}</span
                    >
                  </template>
                </v-select>

                <span class="error-warning" v-if="v$.product_id.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label"> Кол-во товара: </label>

                <input
                  class="main-form-control__input"
                  :class="{ error: v$.quantity.$error }"
                  type="text"
                  placeholder="Введите кол-во товара"
                  v-model="state.quantity"
                />

                <span class="error-warning" v-if="v$.quantity.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label">
                  Номер телефона:
                </label>

                <input
                  class="main-form-control__input"
                  :class="{ error: v$.phone_number.$error }"
                  type="text"
                  placeholder="Введите номер телефона"
                  v-model="state.phone_number"
                  v-maska="'+7 (###) ### ## ##'"
                />

                <span class="error-warning" v-if="v$.phone_number.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label"> Срок резерва: </label>

                <VueDatePicker
                  class="main-form-control__datepicker"
                  :class="{ error: v$.reserve_date.$error }"
                  placeholder="Выберите дату"
                  locale="ru"
                  :enable-time-picker="false"
                  :clearable="false"
                  v-model="state.reserve_date"
                  auto-apply
                ></VueDatePicker>

                <span class="error-warning" v-if="v$.reserve_date.$error"
                  >* Данное поле обязательно</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="px-5">
            <button
              type="button"
              class="btn btn-white btn-md"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md"
              :disabled="state.isFormLoading"
            >
              <span>{{ submitBtnText }}</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

import useModal from "@/mixins/useModal";
import { required, numeric } from "@vuelidate/validators";

export default {
  props: [
    "title",
    "submitBtnText",
    "entityToUpdate",
    "refetch",
    "apiRequest",
    "productOptionList",
  ],
  setup(props) {
    const state = reactive({
      isFormLoading: false,
      full_name: null,
      product_id: null,
      quantity: null,
      phone_number: null,
      reserve_date: null,
    });
    const rules = {
      full_name: { required },
      product_id: { required },
      quantity: { required, numeric, minValue: (item) => item >= 1 },
      phone_number: { required, minLength: (item) => item.length == 18 },
      reserve_date: { required },
    };
    const { modalRef, onSubmitForm, v$, onResetForm } = useModal(props, {
      state,
      rules,
    });

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
    };
  },
};
</script>

<style lang="scss">
.create-task {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 50px;
  }
}

.task-select .vs__selected {
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 37.5px;
}
</style>